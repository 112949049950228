import { usePageContext } from 'application/contexts/PageContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

export const Meta: React.FC<Content.PageContent['page']> = ({ properties }) => {
	const { title, hideFromSearchEngine, description, soMeImage } = properties ?? {};

	const siteContext = useSiteContext();
	const pageContext = usePageContext();
	const { locale: language } = useRouter();
	const { publicRuntimeConfig } = getConfig();
	const { APP_ENV: appEnv, DOMAIN_PROTOCOL: protocol } = publicRuntimeConfig ?? {};
	const { title: siteTitle, theme } = siteContext ?? {};
	const { host, url, name, template, pageLanguageVersions, segment, content, createDate, lastUpdated, id } = pageContext ?? {};
	const ownerTitle = `${siteTitle !== null ? siteTitle : ''} | ${host}`;
	const pageTitle = `${title ? title : name} ${siteTitle !== null ? '| ' + siteTitle : ''}`;
	const metaTitle = `${template !== 'HomePage' ? pageTitle : ownerTitle}`;

	const urlPath = url?.split('?')[0];
	const metaUrlPath = urlPath?.endsWith('/') ? urlPath?.slice(0, -1) : urlPath;
	let metaUrl: string;
	if (!host?.startsWith('http')) {
		metaUrl = `${protocol ? `${protocol}://` : ''}${host}${metaUrlPath}`;
	}
	const subject = content?.subject?.name;
	const updateDate = lastUpdated.split('T')[0];

	return (
		<Head>
			{/* Title */}
			<title>{metaTitle}</title>
			<meta property="og:title" content={metaTitle} />
			<meta name="dc.title" content={metaTitle} />
			{/* Description */}
			<meta name="description" content={description} />
			<meta property="og:description" content={description} />
			<meta name="dc.description" content={description} />
			{/* Category */}
			<meta name="dc.subject" content={subject} />
			<meta name="article:section" content={subject} />
			<meta name="subject" content={subject} />
			{/* Cludo */}
			{segment?.length > 0 && <meta name="Category" content={segment?.map((item) => item.name)?.join('|')} />}
			{template === 'ArticlePage' && <meta name="boost" content="500" />}
			{/* Language */}
			<meta name="lang" content={language.split('-')[0]} />
			<meta name="dc.language" content={language} />
			<meta name="dc.language.iso" content={language.replace('-', '_')} />
			<meta property="og:locale" content={language} />
			{pageLanguageVersions?.map((languageVersion, index) => {
				const { cultureName, pageUrl } = languageVersion ?? {};
				if (cultureName.toLowerCase() === language.toLowerCase() || !cultureName) return;
				return (
					<React.Fragment key={index}>
						<link key={cultureName} rel="alternate" href={pageUrl} hrefLang={cultureName} />
						<meta property="og:locale:alternate" content={cultureName.replace('-', '_')} />
					</React.Fragment>
				);
			})}
			{/* Url */}
			<link rel="sitemap" type="application/xml" href={`${protocol ? `${protocol}://` : ''}${host}/sitemap.xml`} />
			<meta property="og:url" content={metaUrl} />
			<link rel="canonical" href={metaUrl} />
			{/* Image */}
			{soMeImage[0]?.content?.properties?.image?.url ? (
				<>
					<meta property="og:image" content={`${soMeImage[0].content.properties.image.url}?width=1200&height=630&format=webp`} />
					<meta property="og:image:type" content="image/jpeg" />
					<meta property="og:image:width" content="1200" />
					<meta property="og:image:height" content="630" />
					<meta property="og:image:alt" content={soMeImage[0].content.properties.image?.properties?.altText} />
				</>
			) : (
				<>
					<meta property="og:image" content={`/logo/meta-logo/logo-${theme ? theme.toLowerCase() : 'skat'}-dark.png`} />
					<meta property="og:image:type" content="image/png" />
					<meta property="og:image:width" content="1200" />
					<meta property="og:image:height" content="630" />
					<meta property="og:image:alt" content={title} />
				</>
			)}
			{/* Element type */}
			{template === 'HomePage' ? <meta property="og:type" content="website" /> : <meta property="og:type" content="article" />}
			{/* Publisher */}
			<meta name="dc.publisher" content={ownerTitle} />
			<meta property="og:site_name" content={ownerTitle} />
			{/* Date */}
			{new Date(createDate).getTime() < new Date(updateDate).getTime() && (
				<>
					<meta name="dc.date" content={updateDate} />
					<meta property="article:modified_time" content={updateDate} />
				</>
			)}
			{lastUpdated && <meta name="last-updated" content={lastUpdated} />}
			{/* Robots */}
			<meta
				name="robots"
				content={`${hideFromSearchEngine || appEnv !== 'prod' ? 'nofollow, noindex, noarchive, nosnippet, noimageindex' : 'follow, index'}`}
			/>
			{/* OID/PID */}
			<meta name="DSstat.oid" content={`${content.referenceOId ? content.referenceOId : 'noid'}`} />
			<meta name="DSstat.pid" content={id.toString()} />
		</Head>
	);
};
