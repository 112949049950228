import { fireAdobeEvent } from 'helpers/adobeEvent';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { configs } from './ChatbotConfigs';

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
	interface Window {
		widgetObj: any;
		configureWidget: (config: Vendors.ChatbotConfig) => void;
		config: Vendors.ChatbotConfig;
	}
}

export interface ChatbotProps {
	tenantId: string;
	settingsId: '8' | '9' | '11' | string;
	chatbotConfiguration: Vendors.ChatbotConfig;
	chatIntegrityOld?: string;
	chatIntegrityNew?: string;
	defaultOpenChatbot?: boolean;
}

export const Chatbot = ({
	tenantId,
	settingsId,
	chatbotConfiguration,
	chatIntegrityOld,
	chatIntegrityNew,
	defaultOpenChatbot = false,
}: ChatbotProps) => {
	const [mainjsLoaded, setMainjsLoaded] = useState(false);

	useEffect(() => {
		window.widgetObj?.destroyWidget?.();
		if (!chatbotConfiguration) return console.log('no chatbot configuration');
		if (!window.configureWidget) return console.log('no configureWidget');
		const customConfig = {
			...chatbotConfiguration,
			cssOverwrites: configs[settingsId]?.cssOverwrites ?? configs[0]?.cssOverwrites, // fallback to first config
			hideWidgetUnlessOngoingChat: false,
		};
		if (tenantId) {
			customConfig.visitorInfo?.fields?.push({ name: 'entrypointSubject', type: 'hidden', defaultValue: tenantId }); //tenantId = 1008
		} else {
			customConfig.hideWidgetUnlessOngoingChat = true;
		}

		document.addEventListener('supchatevent', (event: CustomEvent) => {
			const command = event.detail?.msg?.command;
			const text = event.detail?.msg?.text;
			if (command === 'status' || command === 'say') {
				const eventValue = { type: 'chatbot' };
				if (text.indexOf('Jeg er Skattestyrelsens chatbot.') > 0) {
					fireAdobeEvent('chat_start', eventValue);
				} else if (text === 'chat_end') {
					fireAdobeEvent('chat_complete', eventValue);
				}
			}
		});

		// script from supwiz
		function getInputValueFromUrl(name) {
			// function that reads params from current url
			const url = window.location.href;
			const results = new RegExp('[?&]' + name + '=([^&#]*)').exec(url);
			if (results == null) return null;
			return decodeURI(results[1]) || '';
		}

		function overwriteWidgetConfig(originalConfig) {
			// function that mutates the widget config
			// We insert values from the url, and make the widget open up automatically
			const newConfig = originalConfig;

			// for simplicity, our url parameter names must match the "Input Name" of the input field
			// for this example, we have 2 fields "name" and "email" which we want to fill out.
			// The url could something like https://example.com/my-subpage?name=John&email=j.doe@example.com
			const inputFields = newConfig.visitorInfo.fields;
			if (Array.isArray(inputFields) && inputFields.length) {
				inputFields.forEach((field, index) => {
					const fieldName = field.name; // this will be either "name" or "email" in the example
					const fieldValueFromUrl = getInputValueFromUrl(fieldName); // value from the url

					if (fieldValueFromUrl !== '') {
						// if there is a value found then we want to insert it
						inputFields[index].defaultValue = fieldValueFromUrl;
					}
				});
			}

			// make the widget open automatically and skip the start page
			newConfig.showStartPage = false;
			newConfig.startOpen = true;

			// return the updated config that the widget should use instead
			return newConfig;
		}

		// we add our overwrite function as the first argument to configureWidget
		// script end
		window.widgetObj = window.configureWidget(defaultOpenChatbot ? overwriteWidgetConfig(customConfig) : customConfig);
	}, [mainjsLoaded, tenantId, settingsId, chatbotConfiguration, defaultOpenChatbot]);

	return (
		chatIntegrityNew &&
		chatIntegrityOld && (
			<Script
				src="https://supchat.skat.supwizapp.com/widget/main.js"
				strategy="lazyOnload"
				integrity={chatIntegrityNew?.length && `sha256-${chatIntegrityNew} ${chatIntegrityOld?.length ? `sha256-${chatIntegrityOld}` : ''}`}
				crossOrigin="anonymous"
				onLoad={() => setMainjsLoaded(true)}
			/>
		)
	);
};
