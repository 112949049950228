import classNames from 'classnames';
import { LoginOption } from 'components/3-organisms/Authentication';
import { Collapse } from '../Collapse';
import { Modal } from '../Modal';
import styles from './LoginModal.module.scss';
import { LoginModalContent } from './LoginModalContent/LoginModalContent';
export interface LoginModalProps {
	show: boolean;
	onClose: () => void;
	ariaLabel?: string;
	ariaLabelClose?: string;
	loginModalContent: LoginOption[];
	loginGlobalAlert?: React.ReactNode[];
}

export const LoginModal: React.FC<LoginModalProps> = ({
	show,
	onClose,
	ariaLabel,
	ariaLabelClose,
	loginModalContent,
	loginGlobalAlert,
}: React.PropsWithChildren<LoginModalProps>) => {
	const modalSize = loginModalContent.length === 1 ? 'small' : 'large';
	return (
		<Modal show={show} onClose={onClose} ariaLabel={ariaLabel} ariaLabelClose={ariaLabelClose} size={modalSize}>
			{loginGlobalAlert && <div className={styles.LoginGlobalAlert}>{loginGlobalAlert}</div>}

			<div className={classNames(styles.LoginModal, loginModalContent.length === 1 && styles.LoginModal___single)}>
				{loginModalContent.map((data, key) => {
					return (
						<div className={styles.LoginModal_content} key={key}>
							{data?.hideInCollapse ? (
								<Collapse header={data.label}>
									<LoginModalContent {...data} />
								</Collapse>
							) : (
								<LoginModalContent {...data} />
							)}
						</div>
					);
				})}
			</div>
		</Modal>
	);
};
