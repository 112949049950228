import classNames from 'classnames';
import { Heading } from 'components/1-atoms/Heading';
import CloseIcon from 'icons/icon-luk.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getKeyboardFocusableElements } from 'utilities/getKeyboardFocusableElements';
import styles from './Navigation.module.scss';
import { NavigationCollapse } from './NavigationCollapse';
import { NavigationItem, NavigationItemProps } from './NavigationItem';

export interface NavigationProps {
	className?: string;
	style?: 'list' | 'header';
	heading?: string;
	navigationItems?: NavigationItemProps[];
	extraBurgerItems?: NavigationItemProps[];
	logoarea?: React.ReactNode;
	onClick?: () => void;
	burgerActive?: boolean;
	openText?: string;
	closeText?: string;
}

export const Navigation: React.FC<NavigationProps> = ({
	className,
	style,
	heading,
	navigationItems,
	extraBurgerItems,
	logoarea,
	onClick,
	burgerActive,
	openText,
	closeText,
	...props
}) => {
	const nav = useRef<HTMLElement>(null);
	const [collapsed, setCollapsed] = useState<number[]>([]);
	const [focusElements, setFocusElements] = useState(null);

	useEffect(() => {
		const focusables = getKeyboardFocusableElements(nav?.current);
		setFocusElements(focusables);
	}, [collapsed]);

	const focusLast = useCallback(
		(event: KeyboardEvent) => {
			if (event.code === 'Tab' && event.shiftKey) {
				event.preventDefault();
				focusElements[focusElements.length - 1].focus();
			}
		},
		[focusElements],
	);
	const focusFirst = useCallback(
		(event: KeyboardEvent) => {
			if (event.code === 'Tab' && !event.shiftKey) {
				event.preventDefault();
				focusElements[0].focus();
			}
		},
		[focusElements],
	);

	useEffect(() => {
		if (burgerActive && focusElements) {
			focusElements[1].focus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [burgerActive]);

	useEffect(() => {
		if (!burgerActive || !focusElements) return;

		const first = focusElements[0];
		const last = focusElements[focusElements.length - 1];

		first.addEventListener('keydown', focusLast);
		last.addEventListener('keydown', focusFirst);
		return () => {
			first.removeEventListener('keydown', focusLast);
			last.removeEventListener('keydown', focusFirst);
		};
	}, [focusElements, focusFirst, focusLast, burgerActive]);

	const setStyle = (index: number) => {
		if (collapsed.indexOf(index) === -1) {
			setCollapsed([...collapsed, index]);
			return;
		}
		setCollapsed(collapsed.filter((item) => item !== index));
	};
	return (
		<nav ref={nav} className={classNames(styles.Navigation, styles[`Navigation___${style}`], className)} {...props}>
			<div className={styles.Navigation_burgerOptions}>
				<div className={styles.Navigation_logo}>{logoarea}</div>
				<button className={styles.Navigation_close} onClick={onClick} aria-label={closeText}>
					<CloseIcon />
				</button>
			</div>
			{heading && (
				<Heading headingLevel="h2" style="sm" className={styles.Navigation_heading}>
					{heading}
				</Heading>
			)}
			{navigationItems || extraBurgerItems ? (
				<ul className={styles.Navigation_list}>
					{navigationItems.length > 0 &&
						navigationItems.map((navigationItem, index) => {
							const id = navigationItem?.name?.replace(/\s/g, '') + index;
							return (
								<NavigationItem
									{...navigationItem}
									key={id}
									style={style}
									callback={onClick}
									collapsed={collapsed.indexOf(index) !== -1}
								>
									{navigationItem.descendants && style === 'header' && (
										<>
											<NavigationCollapse
												items={navigationItem.descendants}
												setStyle={() => setStyle(index)}
												openText={openText}
												closeText={closeText}
												callback={onClick}
											/>
										</>
									)}
								</NavigationItem>
							);
						})}
					{burgerActive &&
						extraBurgerItems?.length > 0 &&
						extraBurgerItems.map((item: NavigationItemProps, index: number) => (
							<NavigationItem
								className={styles.Navigation_extraNavItems}
								{...item}
								key={navigationItems.length + index}
								style={style}
								callback={onClick}
							/>
						))}
				</ul>
			) : null}
		</nav>
	);
};
