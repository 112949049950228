import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { Login } from 'components/3-organisms/Authentication';
import { CTALink } from 'designsystem/components/1-atoms/Links';
import { fireAdobeEvent } from 'helpers/adobeEvent';
import { getAbsoluteUrl } from 'helpers/urlHandlers';
import { useRouter } from 'next/router';
import { AlertFeature } from '../Alert';

export const LoginFeature = () => {
	const dictionary = useDictionaryContext();
	const siteContext = useSiteContext();
	const pageContext = usePageContext();
	const router = useRouter();
	const { loginEnabled, loginModalContent, loginGlobalAlert } = siteContext?.settings?.loginConfiguration ?? {};

	const handleTracking = (values: { segmentName: string; link: Content.Link }) => {
		const { segmentName, link } = values ?? {};

		const href = getAbsoluteUrl(link.url);
		fireAdobeEvent('cta_clicks', {
			buttonName: link.name,
			pageName: pageContext.name,
			href,
		});
		fireAdobeEvent('login_click', { type: `${segmentName}:${link.name} ` });
	};

	const handleLoginClick = (e, segmentName, link) => {
		e.preventDefault();
		handleTracking({ segmentName, link });
		link.target ? window.open(link.url) : router.push(link.url);
	};

	const closeModal = () => {
		const [path, query] = router.asPath.split('?');
		const searchParams = new URLSearchParams(query);
		searchParams.delete('login');
		router.replace(`${path}?${searchParams.toString()}`), undefined, { shallow: true };
	};

	// return loginEnabled ? (
	// 	<Login
	// 		loginText={dictionary.getAction('LogOn')}
	// 		loginModalContent={loginOptions}
	// 		show={router.query.login === 'open'}
	// 		callBack={() => router.replace(router.asPath.replace('?login=open', ''), undefined, { shallow: true })}
	// 	/>
	// ) : null;

	// Temporary overruling of loginEnabled due to release of mit.gaeldsoverblik.gaeldst.dk
	return (
		<Login
			loginText={dictionary.getAction('LogOn')}
			loginModalContent={loginModalContent?.map((data) => {
				const { label, link, content, segment, loginAlert } = data.content.properties ?? {};
				const { hideContentInCollapse } = data?.settings?.properties ?? {};
				const { cid: campaignId } = router.query ?? {};
				const searchParams = new URLSearchParams();
				searchParams.set('cid', campaignId as string);
				const linkWithCid = {
					name: link.name,
					url: `${link.url}${campaignId ? '?' + searchParams.toString() : ''}`,
					target: link.target,
				};

				return {
					label,
					link: link && (
						<CTALink
							label={linkWithCid.name}
							color="dark"
							link={linkWithCid}
							type="logon"
							onClick={(e) => handleLoginClick(e, segment?.name, linkWithCid)}
						/>
					),
					content,
					hideInCollapse: hideContentInCollapse,
					loginAlert: loginAlert?.map((data, index) => <AlertFeature key={index} {...data} />),
				};
			})}
			show={router.query.login === 'open'}
			hideButton={loginEnabled ? false : true}
			loginGlobalAlert={loginGlobalAlert?.map((data, index) => (
				<AlertFeature key={index} {...data} />
			))}
			callBack={closeModal}
		/>
	);
};
