import classNames from 'classnames';
import { ImageOptimizationComponent, ImageOptimizationContextProvider } from '../../../contexts/ImageOptimizationContext';
import styles from './Layout.module.scss';

export interface LayoutProps {
	children: React.ReactNode;
	imageOptimization: ImageOptimizationComponent;
	themeClass: string;
}

export const Layout: React.FC<LayoutProps> = ({ children, imageOptimization, themeClass }) => {
	return (
		<ImageOptimizationContextProvider imageOptimization={imageOptimization}>
			<div className={classNames(styles.Layout, themeClass)}>{children}</div>
		</ImageOptimizationContextProvider>
	);
	
};

