import { Layout } from 'designsystem/components/4-habitats/Layout';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FooterFeature } from '../Footer';
import { HeaderFeature } from '../Header';
import { MainFeature } from '../Main/MainFeature';
import { Meta } from './Meta';
import { Vendors } from './Vendors';

export type LayoutStyle = 'simple' | 'simpleNoClose';

type NextImageProps = React.ComponentProps<typeof Image>;

const ImageOptimization = (props: NextImageProps) => {
	return <Image {...props} alt={props?.alt ?? ''} />;
};

export const LayoutFeature: React.FC<Content.PageContent> = ({ children, page, footer, root }) => {
	const { siteTheme: themeName, navigationItems } = root?.properties ?? {};
	const router = useRouter();
	const layoutStyle = router.query.layout as LayoutStyle;
	const themeClass = 'theme-' + themeName?.toLowerCase();

	return (
		<Layout themeClass={themeClass} imageOptimization={ImageOptimization}>
			<Meta {...page} />
			<Vendors {...page} />
			<HeaderFeature navigationItems={navigationItems} layout={layoutStyle} />
			<MainFeature>{children}</MainFeature>
			{footer ? <FooterFeature footer={footer} layout={layoutStyle} /> : null}
		</Layout>
	);
};
