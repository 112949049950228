import { RichText } from 'components/1-atoms/RichText';
import styles from './LoginModalContent.module.scss';
export interface LoginModalContentProps {
	className?: string;
	link: JSX.Element;
	content?: string;
	loginAlert?: React.ReactNode[];
}

export const LoginModalContent = ({ link, content, loginAlert }: LoginModalContentProps) => {
	return (
		<>
			{loginAlert}
			{link}
			<RichText className={styles.LoginModalContent_text} content={content} />
		</>
	);
};
