export type AdobeEvent =
	| 'abort'
	| 'accordion_open'
	| 'accordion_link_click'
	| 'arrow_link'
	| 'ajaxPageView'
	| 'back_button_click'
	| 'chat_open'
	| 'chat_start'
	| 'chat_complete'
	| 'cta_clicks'
	| 'emne_tekst_rm_click'
	| 'file_download'
	| 'footer_link'
	| 'form_download'
	| 'form_error'
	| 'form_view'
	| 'form_start'
	| 'form_step'
	| 'form_step_complete'
	| 'greater_than_btn'
	| 'int_search_filter_selection'
	| 'legal_click'
	| 'login_click'
	| 'modal_click'
	| 'pageView'
	| 'phone_clicked'
	| 'read_out_loud'
	| 'sister_click'
	| 'situationselector_click';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fireAdobeEvent = (eventName: AdobeEvent, value: any) => {
	// TODO: statx is not guaranteed to be available here.
	window?.statx
		? window.statx.fireEvent(eventName, value)
		: setTimeout(() => {
				window?.statx ? window.statx.fireEvent(eventName, value) : console.log('statx not active');
		  }, 500);
};
